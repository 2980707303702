// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

//Variables
$white-elements-color: #fff;
$blue-elements-color: #00d2eb;

//Common
html {
  scroll-behavior: smooth;
}

.portfolio .thumbnail-inner {
  &::before {
    display: none;
  }

  &:hover {
    transform: scale(1.02) !important;
  }
}

//.slider-wrapper {
//  width: 90vw;
//}

//.slick-slider {
//  width: 1200px;
//}

.ImageContainer {
  margin: 0 auto 20px;
  height: 800px;
  width: 415px;
  background-image: url('/assets/images/about/about-5.png');
  background-size: cover;

  &_Landing {
    background-image: url('/assets/images/about/about-5.jpg');
  }

  @media only screen and (max-width: 767px) {
    height: 600px;
    width: 310px;
  }
}

.Image2Container {
  margin: 0 auto 20px;
  width: 600px;
  height: 330px;
  background-image: url('/assets/images/about/about-6.jpg');
  background-size: cover;

  &_Landing {
    background-image: url('/assets/images/about/about-6.png');
  }

  @media only screen and (max-width: 767px) {
    width: 600px;
    height: 330px;
  }

  @media only screen and (max-width: 541px) {
    width: 84vw;
    height: 47vw;
  }

  @media only screen and (max-width: 376px) {
    width: 300px;
    height: 165px;
  }
}

.row--35>[class*=col], .row--35>[class*=col-] .CarImage {
  @media only screen and (max-width: 767px) {
    padding: 0 !important;
  }
}

//Header
.Header {

  &_Logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    @media only screen and (max-width: 767px) {
      width: 60px !important;
    }
  }
}

#dropdown-basic {
  background-color: $white-elements-color;
  color: $theme-color;
  box-shadow: none;

  &:hover {
    color: $white-elements-color;
    background-color: $theme-color;
  }

  &:active {
    background-color: $theme-color;
  }
}

.personal-portfolio-slider::before {
  display: none;
}

.slider-wrapper {
  @media only screen and (max-width: 767px) {
    //background: linear-gradient(to right, #000, #444);
  }

  @media only screen and (max-width: 541px) {
    //height: 100vh !important;
  }
}

.bg_image {
  background-image: url("/assets/images/bg/bg-image-16.png") !important;
  background-position: left;

  @media only screen and (max-width: 1280px) {
    background-position: left !important;
  }

  @media only screen and (max-width: 541px) {
    background-image: url("/assets/images/bg/bg-image-16_mb.png") !important;
    background-position: top !important;
    min-height: 90vh;
  }
}

.detailing .bg_image {

  background-image: url("/assets/images/bg/bg-image-17.jpg") !important;
  background-position: left;

  @media only screen and (max-width: 1280px) {
    background-position: left !important;
  }

  @media only screen and (max-width: 541px) {
    background-image: url("/assets/images/bg/bg-image-17_mb.jpg") !important;
    background-position: top !important;
    min-height: 90vh;
  }
}

.LanguageSelector {
  margin-bottom: 0;
  padding: 0 5px !important;
  text-align: center;
  background-color: transparent !important;
  border-color: $theme-color !important;
  outline: none;

  &:hover {
    border-color: $theme-color !important;
    color: $white-elements-color !important;
    background-color: $theme-color !important;
  }
}

.BlueText {
  color: $blue-elements-color !important;
}

.ThemeText {
  color: $theme-color !important;

  @media only screen and (max-width: 575px) {
    margin-bottom: 80px !important;
  }
}

.WhiteText {
  color: $white-elements-color;
}

.MainTitle {
  text-transform: uppercase !important;
  text-align: center;
  margin-right: 0 !important;

  @media only screen and (max-width: 575px) {
    margin-bottom: 120px !important;
    font-size: 24px !important;
    line-height: 36px !important;
  }
}

.description {
  text-transform: uppercase !important;
}

//.about-area {
//  background-color: $blue-elements-color !important;
//}

.service__style--2 {
  padding: 10px !important;
}

.title {
  font-size: 36px !important;
  color: $theme-color !important;
}

.FlexColumns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-left .inner::after {
  content: none !important;
}

.Slider-Item {
  width: 600px !important;
}

.mh-100 {
  max-height: 100%;
}

.PromiseToCall {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 14px !important;
}

//Pricing
.PriceTitle {
  font-size: 64px !important;
  @media only screen and (max-width: 541px) {
    font-size: 48px !important;
  }
}

.PricingCard {
  @media only screen and (max-width: 541px) {
    padding: 0 !important;
  }
}

.pricing-body {
  margin-bottom: 0 !important;
}

.rn-pricing .pricing-table-inner {
  @media only screen and (max-width: 541px) {
    padding: 10px !important;
  }
};

.rn-pricing .pricing-table-inner .pricing-header h4.title {
  @media only screen and (max-width: 541px) {
    margin-bottom: 0 !important;
  }
};

.rn-pricing .pricing-table-inner .pricing-header {
  @media only screen and (max-width: 541px) {
    margin-bottom: 10px;
    padding-bottom: 0;
    text-align: center;
    border-bottom: 1px solid rgba(248,31,1,.1);
  }
};

.rn-pricing .pricing-table-inner .pricing-header h4.title {
  @media only screen and (max-width: 541px) {
    font-size: 18px !important;
  }
};

.rn-pricing .pricing-table-inner .pricing-header .pricing span.price {
  @media only screen and (max-width: 541px) {
    font-size: 24px !important;
  }
};

.list-style--1 li {
  @media only screen and (max-width: 541px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.rn-pricing .pricing-table-inner .pricing-body {
  @media only screen and (max-width: 541px) {
    margin-bottom: 10px;
  }
}

.service.service__style--2:hover {
  cursor: auto;
}

.service.service__style--2::before {
  //position: absolute;
  //content: "";
  //left: 0;
  //top: 0;
  //background-image: linear-gradient(to right, #000, #5f5f5f);
  //width: 100%;
  //height: 100%;
  ////z-index: -1;
  //opacity: 1;
  //visibility: visible; !important;
  //border-radius: 10px;
}

.service.service__style--2.RiscDescription::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-image: linear-gradient(to right, #000, #5f5f5f);
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1 !important;
  visibility: visible !important;
  border-radius: 10px;
}

.RiscDescription:hover .BlueText {
    color: #fff !important;
}

.FooterPhoneLinkContainer {
  padding-top: 10px;
}

.FooterPhoneLink {
  color: #c6c9d8;
  font-size: 18px;

  &:hover {
    color: #00d2eb;
  }
}

.FooterStandWithUkraine {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.FooterUkraineIcon {
  width: 40px;
  height: 40px;
}

.mainmenunav ul.mainmenu>li {
  margin: 0 5px !important;
}

.rn-btn {
  @media (min-width: 960px) and (max-width: 1200px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.PageSelectorContainer {
  padding-right: 10px;
}

.PageSelector {
  min-width: 140px;
  background-color: transparent !important;
}

//.NavLink {
//  color: #ecdec1 !important;
//
//  &:hover {
//    cursor: pointer !important;
//    color: $theme-color !important;
//  }
//}

.ImportantLink {
  font-weight: 700 !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-color: #000000 #ffffff;
  scrollbar-width: thin;

  &:hover {
    cursor: pointer;
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 4px;
  border: 2px solid #ffffff;

  &:hover {
    cursor: pointer;
  }
}
